.lottie {
  width: 100%;
  height: 60vh;
  padding-bottom: 50px;
}

.lottie-m {
    width: 100%;
    height: 30vh;
    padding-bottom: 25px;
  }