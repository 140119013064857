.carousel-container {
  padding: 15px 0px 30px 0px;
}

.carousel-title {
  margin-top: 50px;
  font-size: 24px;
  height: 10px;
}

.carousel-title-m {
  margin-top: 50px;
  font-size: 20px;
  height: 0;
}

.carousel-subtitle {
  width: 300px;
}
