.swiper {
  width: 240px;
  height: auto
}

.swiper-slide {
  border-radius: 24px;
  border: 10px solid rgba(255, 255, 255, 0.5);
}

.slider-image {
  height: inherit;
  width: inherit;
  border-radius: 14px;
}

.pagination-container {
  height: 100px;
  width: 100px;
  flex: 1;
  display: flex;
}

.swiper-pagination {
  bottom: -50px !important;
}
