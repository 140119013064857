.headerContainer {
  display: flex;
  justify-content: space-between;
}

.icon {
  width: 40px;
  height: 40px;
}

.headerTitle {
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.boldedTitle {
  padding-right: 5px;
}

.navLinks {
  padding: 10px 15px;
}

.header-btn {
  border: 0;
  background: transparent;
  color: #8c88c9;
  cursor: pointer;
}
