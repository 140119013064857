.device-frame-container {
  position: absolute;
  top: 0;
}

.device-frame-container-m {
  position: absolute;
  top: 60px;
  padding-left: 15px;
}

.outer-box {
  border: 4px solid #ffffff;
  border-radius: 62px;
  position: absolute;
  background: transparent;
  top: 50px;
}

.inner-box {
  position: relative;
  background: transparent;
  border: 30px solid rgba(255, 255, 255, 0.5);
  border-radius: 57px;
  overflow: hidden;
}

.inner-box-m {
  position: relative;
  background: transparent;
  border: 10px solid rgba(255, 255, 255, 0.5);
  border-radius: 57px;
  overflow: hidden;
}

.frame-content {
  position: relative;
  width: 220px;
  height: auto;
  margin-bottom: -4px;
}

.frame-content-m {
  position: relative;
  width: 200px;
  height: auto;
  margin-bottom: -4px;
}

.video {
  width: 220px;
  height: auto;
  margin-bottom: -4px;
}

.video-m {
  width: 200px;
  height: auto;
  margin-bottom: -4px;
}
