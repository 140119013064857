.footer-container {
  color: #aeafc3;
  font-size: 18px;
  margin: 11% 2% 1% 2%;
}

.footer-link {
  padding: 0px 20px;
}

.footer-link-m {
  padding: 20px 0px;
}