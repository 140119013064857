body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f6f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root {
  height: 100%;
}

/* CUSTOM STYLES BELOW */

@font-face {
  font-family: "Graphik-Regular";
  src: local("GraphikRegular"),
    url(./assets/fonts/GraphikRegular.otf) format("otf");
  src: url(./assets/fonts/Graphik-Regular.woff) format("woff");
  src: url(./assets/fonts/Graphik-Regular.woff2) format("woff2");
  font-display: "swap";
}

@font-face {
  font-family: "Graphik-Semibold";
  src: local("GraphikSemibold"),
    url(./assets/fonts/GraphikSemibold.otf) format("otf");
  src: url(./assets/fonts/Graphik-Semibold.woff) format("woff");
  src: url(./assets/fonts/Graphik-Semibold.woff2) format("woff2");
  font-display: "swap";
}

@font-face {
  font-family: "Graphik-Medium";
  src: local("GraphikMedium"),
    url(./assets/fonts/GraphikMedium.otf) format("otf");
  src: url(./assets/fonts/Graphik-Medium.woff) format("woff");
  src: url(./assets/fonts/Graphik-Medium.woff2) format("woff2");
  font-display: "swap";
}

.semibold {
  font-family: "Graphik-Semibold";
}

.regular {
  font-family: "Graphik-Regular";
}

.medium {
  font-family: "Graphik-Medium";
}

.lh24 {
  line-height: 24px;
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
}

.fs24 {
  font-size: 24px;
}

.fs40 {
  font-size: 40px;
}

.fs60 {
  font-size: 60px;
}

a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}

.middlePurple {
  color: #8c88c9;
}

.darkSalmon {
  color: #e59584;
}

.pd15 {
  padding: 15px 25px;
  overflow: hidden;
}

.pdt30 {
  padding-top: 30px;
}

.height5 {
  height: 5px;
}

.height10 {
  height: 10px;
}

.height30 {
  height: 30px;
}

.height60 {
  height: 60px;
}

.flex {
  display: flex;
}

.centered {
  justify-content: center;
  text-align: center;
  align-items: center;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

.space-between {
  justify-content: space-between;
}

.text-align-center {
  text-align: center;
}

.column {
  flex-direction: column;
}

.cursor {
  cursor: pointer;
}

.footer-container {
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
}
