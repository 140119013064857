.initial-cta-container {
  margin-top: 100px;
  margin-left: 50px;
}

.initial-cta-container-t {
  margin-top: 100px;
}

.initial-cta-container-m {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 25px;
}

.initial-cta-subtitle {
  font-size: 20px;
  width: 65%;
  padding-top: 15px;
  line-height: 24px;
}

.initial-cta-subtitle-m {
  padding-top: 15px;
  line-height: 24px;
}

.initial-cta-apple-btn {
  display: block;
  width: fit-content;
  padding: 15px 0px 85px;
}
